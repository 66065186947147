<template>
  <base-section id="our-concerns" class="bac-section">
    <base-section-heading title="Our Concerns">
      There are numerous things that worry us about the Marina's construction
      plans,<br />but here are a few of the highlights:
    </base-section-heading>

    <v-container style="max-width: 1200px;">
      <v-row>
        <v-col v-for="(concern, i) in concerns" :key="i" cols="12" md="6">
          <base-avatar-card v-bind="concern" align="left" horizontal>
            <span v-html="concern.text"></span>
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "OurConcerns",

  data: () => ({
    concerns: [
      {
        title: "Increased Flood Risk",
        icon: "mdi-waves",
        text:
          "Filling all that open space with new buildings will push flood waters further into the surrounding historic neighborhood"
      },
      {
        title: "Destruction of Historic Building",
        icon: "mdi-alert-octagon",
        text:
          "Built before the Civil War, this Plymouth landmark deserves better than to be bulldozed to make way for something new"
      },
      {
        title: "New Fuel Storage Area",
        icon: "mdi-fuel",
        text:
          "The new above-ground fuel storage will be located next to a residential site as well as a school yard"
      },
      {
        title: "New Network of Fuel Pipes",
        icon: "mdi-pipe",
        text:
          "The new fuel tank location necessitates underground pipes running nearly the entire length of the marina"
      },
      {
        title: "Changing the Character of our Neighborhood and Waterfront",
        icon: "mdi-domain",
        text:
          "Changing the marina to wall of warehouses would turn our historic neighborhood into an industrial district"
      },
      {
        title: "Environmental Impact",
        icon: "mdi-water-pump",
        text:
          "Increasing the intensity of use on a small site directly adjacent to the harbor and Town Brook"
      }
    ]
  })
};
</script>
